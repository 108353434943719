<template>
	<div class="p-3 liff-reservation-confirm">
		<b-card header-tag="header" class="mb-3 liff-reservation-confirm__card">
			<template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約人資訊</h6>
        </div>
      </template>
			<b-form-group label="姓名" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.name" class="text-right" /> -->
         <div class="text-right s-text-sm">{{ user.name }}</div>
			</b-form-group>
			<b-form-group label="電話" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm">
				<!-- <b-form-input v-model="form.phone" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ user.mobile_phone }}</div>
			</b-form-group>
		</b-card>

		<b-card header-tag="header" class="mb-3 liff-reservation-confirm__card">
			<template #header>
				<div class="d-flex justify-content-between align-items-center">
					<h6 class="mb-0">預約資訊</h6>
				</div>
			</template>
			<b-form-group label="項目名稱" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.serviceName" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ service.name }}</div>

			</b-form-group>
			<b-form-group label="服務人員" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.servicePerson" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ staff.name }}</div>

			</b-form-group>
			<b-form-group label="預約時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.serviceTime" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ moment(schedule.start_at).format('YYYY-MM-DD HH:mm') }}</div>
			</b-form-group>
			<b-form-group label="預約結束時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="schedule.end_at">
				<!-- <b-form-input v-model="form.serviceTime" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ moment(schedule.end_at).format('YYYY-MM-DD HH:mm') }}</div>
			</b-form-group>
			<b-form-group label="服務地點" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
				<!-- <b-form-input v-model="form.serviceLocation" class="text-right" /> -->
        <div class="text-right s-text-sm">{{ branch.name }}</div>

			</b-form-group>
		</b-card>

		<div class="mb-3">
			<DynamicForm
        ref="dynamicForm"
        :input-subjects="fields"
        v-model="dynamicFormInput"
      >
      </DynamicForm>
		</div>

		<div class="d-flex s-space-x-2">
			<SharedButton class="s-btn-outline-primary w-50" @click="prevStep">上一步</SharedButton>
			<SharedButton class="s-btn-bg-primary w-50" @click="done">確認預約</SharedButton>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';
import bookingPresetApi from '@/apis/liff/v2/booking-preset';
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import reservationMixin from "@/mixins/liff/reservation";
import bookingApi from '@/apis/liff/v2/booking';

	export default {
		mixins: [reservationMixin],
		components: {
			SharedButton,
			DynamicForm,
		},
		data() {
			return {
				dynamicFormInput: {},
				form: {
					name: '林曉娟',
					phone: '0987654321',
					serviceName: '精緻剪髮、歐系染髮、洗髮',
					servicePerson: 'Nymphia',
					serviceTime: '2024/01/01 09:00',
					serviceLocation: '台北市中山區中山北路二段36巷36號',
					note: '我幫我媽預約，媽媽姓名王美花',
				},
				bookingPreset: {},
			}
		},
		computed: {
			fields() {
				return this.bookingPreset?.config?.fields.map(field => {
          field.id = field.config._id
          return field
        })
			},
			moment () {
        return moment
      },
		},
		mounted() {
			this.init()
		},
    methods: {
      init() {
        this.fetchPreset()
        this.dynamicFormInput = this.otherData
        console.log(this.schedule)
      },
      async fetchPreset() {
        const data = await bookingPresetApi.get(this.$route.params.bookingPresetId);
        this.bookingPreset = data
      },
      prevStep() {
        this.prevStepByList("LiffReservationConfirm", {
          branch_id: this.branchId,
          staff_id: this.staffId
        })
      },
      async submit() {
        this.isLoading = true
        try {
          const dataToSave = {
            preset_id: this.$route.params.bookingPresetId,
            fields: this.dynamicFormInput,
            staff_id: this.staff.id,
            branch_id: this.branch.id,
            service_id: this.service.id,
            start_at: this.schedule.start_at,
            end_at: this.schedule.end_at
          }

          this.set('other', this.dynamicFormInput)

          await bookingApi.saveBooking(dataToSave)
          this.$swal.fire({ title: "送出成功", type: "success" })
        } catch (e) {
          console.error(e)
          this.$swal.fire({ title: "送出失敗", type: "error" })
        }
        this.isLoading = false
      },
      done() {
        this.submit()
        this.$router.push({
          name: 'LiffReservationSuccess'
        })
      }
    }
	}

</script>

<style lang="scss" scoped>
.liff-reservation-confirm {
	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>
